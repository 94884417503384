<template>
  <div class="farming_distribution fl_c">
    <div class="title_common">
		<img src="@/assets/img/header/indicate.png"/>
		<span>返乡人才信息介绍</span>
	</div>
    <div class="fl zindex1 fl_c content_box">
		<span class="lt_icon"></span>
		<span class="rt_icon"></span>
		<div class="fltop fl_r">
			<div class="imgbox fl_r ju_c al_c">
				<img 
					class="img"
					:src="$store.state.talents.item.img == '' ? require('../../assets/img/talents_bg.png') : $store.state.talents.item.img"
				/>
			</div>
			<div class="topfontbox fl_c">
				<div class="fl fl_r">
					<div class="font14 fl_r al_c ju_e ">姓名：</div>
					<div class="font14 fl_r al_c width50">
						{{ $store.state.talents.item.name }}
					</div>
				</div>
				<div class="fl fl_r">
					<div class="font14 fl_r al_c ju_e">性别：</div>
					<div class="font14 fl_r al_c width50">
						{{ $store.state.talents.item.sex }}
					</div>
				</div>
				<div class="fl fl_r">
					<div class="font14 fl_r al_c ju_e">党员：</div>
					<div class="font14 fl_r al_c width50">
						{{ $store.state.talents.item.is_party == `1` ? `是` : `否` }}
					</div>
				</div>
				<div class="fl fl_r">
					<div class="font14 fl_r al_c ju_e">毕业院校：</div>
					<div class="font14 fl_r al_c width50">
						{{ $store.state.talents.item.most_school}}
					</div>
				</div>
			</div>
		</div>
		<div class="flbottom fl_c">
			<div class="fl_r fl" style="margin-bottom: 16px;">
				<div class="font14 fl_r al_c">户籍地址：</div>
				<div class="fl_r al_c font14">
					{{ $store.state.talents.item.now_add }}
				</div>
			</div>
			<div class="fl_r fl">
				<div class="font14 fl_r al_c">工作地址：</div>
				<div class="fl_r al_c font14">
					{{ $store.state.talents.item.now_work_address }}
				</div>
			</div>
		</div>
      <!-- <div class="fl fl_c">
        <div class="fl fl_r">
          <div class="fl fl_r">
            <div class="fl font18">姓名</div>
            <div class="fl font18">{{ $store.state.talents.item.name }}</div>
          </div>
          <div class="fl fl_r">
            <div class="fl font18">性别:</div>
            <div class="fl font18">{{ $store.state.talents.item.sex }}</div>
          </div>
        </div>
      </div>
      <div class="flex4 fl_r font18">
        <div class="flex4 fl fl_c">
          <div class="fl fl_r">
            <div class="fl font18">是否入党:</div>
            <div class="fl font18">
              {{ $store.state.talents.item.is_party == `1` ? `是` : `否` }}
            </div>
          </div>
          <div class="fl fl_r">
            <div class="fl font18">户籍:</div>
            <div class="fl font18">
              {{ $store.state.talents.item.now_add }}
            </div>
          </div>
          <div class="fl fl_r">
            <div class="fl font18">毕业院校:</div>
            <div class="fl font18">
              {{ $store.state.talents.item.most_school }}
            </div>
          </div>
          <div class="fl fl_r">
            <div class="fl_r fl font18">
              <div class="fl font18">工作地点:</div>
              <div class="fl">
                {{ $store.state.talents.item.now_work_address }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex2 fl_r ju_c al_c">
          <img
            class="img"
            :src="`https://vlog1.ichuangnong.com/prod-api//${
              JSON.parse($store.state.talents.item.img)[0].data
            }`"
            alt=""
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TalentsCount",
  props: {
    cropData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nowMap: ``,
    };
  },
  methods: {},
  mounted() {
    this.$nextTick((e) => {});
  },
};
</script>

<style scoped lang="scss">
.farming_distribution {
  width: 100%;
  height: 100%;
}

.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
  margin-top: 12px;
}

.content_box {
	letter-spacing: 1px;
	padding: 24px 34px;
	position: relative;
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
}

.fltop {
  flex: 200;
}
.flbottom {
  flex: 80;
  margin-top: 16px;
}
.imgbox {
  flex: 120;
}
.topfontbox {
  flex: 270;
  padding: 0 34px;
}
.font18 {
  font-size: 18px;
}
img {
  width: 94px;
  height: 132px;
  border-radius: 5%;
  object-fit: cover;
}
.flex3 {
  flex: 3;
}
.flex2 {
  flex: 2;
}
.flex4 {
  flex: 4;
}
.lt_icon {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
}
.rt_icon::after {
  content:'';
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 0;
}
</style>
